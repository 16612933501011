import { createApp } from 'vue';
import Menu from './components/Menu.vue';
import SiteSwitcher from './components/SiteSwitcher.vue';
import Featured from './components/Featured.vue';
import Testimonials from './components/Testimonials.vue';
import MikeMenu from './components/MikeMenu.vue';

const components = {
  'data-vue-menu': Menu,
  'data-vue-site-switcher': SiteSwitcher,
  'data-vue-featured': Featured,
  'data-vue-testimonials': Testimonials,
  'data-vue-mike-menu': MikeMenu
};

Object.entries(components).forEach(([selector, component]) => {
  const element = document.querySelector(`[${selector}]`);
  if (element) {
    const props = Object.entries(element.dataset).reduce((acc, [key, value]) => {
      acc[key] = value;
      return acc;
    }, {});
    
    createApp(component, props).mount(element);
  }
});

if (import.meta.hot) {
  import.meta.hot.accept();
}
