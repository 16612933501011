<template>
  <div class="inline-flex">
    <button @click="openMenu" type="button" class="hover:text-sky-800">
      <span class="sr-only">Menu</span>
      <svg class="size-7" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor"><path fill-rule="evenodd" d="M2 3.75A.75.75 0 0 1 2.75 3h10.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 3.75ZM2 8a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H2.75A.75.75 0 0 1 2 8Zm0 4.25a.75.75 0 0 1 .75-.75h10.5a.75.75 0 0 1 0 1.5H2.75a.75.75 0 0 1-.75-.75Z" clip-rule="evenodd" /></svg>
    </button>

    <TransitionRoot :show="isOpen" as="template">
      <Dialog @close="closeMenu" class="relative z-20">
        <TransitionChild as="template" enter="duration-500" enter-from="opacity-0" enter-to="opacity-100" leave="duration-500" leave-from="opacity-100" leave-to="opacity-0" >
          <div class="fixed inset-0 bg-black/30" aria-hidden="true" />
        </TransitionChild>

        <div class="fixed inset-0 overflow-hidden">
          <div class="absolute inset-0 overflow-hidden">
            <div class="pointer-events-none fixed inset-y-0 left-0 flex max-w-full pr-[4.25rem]">
              <TransitionChild as="template" enter="duration-500" enter-from="-translate-x-full" enter-to="translate-x-0" leave="duration-500" leave-from="translate-x-0" leave-to="-translate-x-full">
                <DialogPanel class="pointer-events-auto w-screen max-w-sm">
                  <div class="h-full bg-white overflow-y-scroll">
                    <nav class="divide-y divide-slate-200 border-b border-slate-200">
                      <a v-for="item in navItems" :key="item.name" :href="item.uri" class="block hover:text-sky-800 text-xs font-semibold uppercase focus:outline-none p-5">{{ item.name }}</a>
                    </nav>
                  </div>
                </DialogPanel>
              </TransitionChild>
            </div>
          </div>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>    
</template>

<script setup>
import { ref } from 'vue'
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue'

const isOpen = ref(false)

const navItems = [
  { name: 'Home', uri: '/' },
  { name: 'About', uri: 'about' },
  { name: 'In the Press', uri: 'in-the-press' },
  { name: 'Speaking Engagements', uri: 'speaking-engagements' },
  { name: 'Leaders Podcast', uri: 'leaders-podcast' },
  { name: 'Contact', uri: 'contact' },
]

function openMenu() {
  isOpen.value = true
}

function closeMenu() {
  isOpen.value = false
}
</script>
